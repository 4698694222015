.custom-navbar-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.custom-navbar-toggle:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
}

.brand-logo-mobile {
  margin-left: auto; /* Align to the right on mobile screens */
  color: white;
  font-family: cursive;
}

.custom-navbar {
  background-color: rgba(0, 0, 0, 0.7); /* Dark transparent background color */
}

.transparent-navbar {
  background-color: rgba(
    0,
    0,
    0,
    0.4
  ); 
  backdrop-filter: blur(4px);
}

/* .image-text {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  z-index: 1;
} */