.custom-gap-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .custom-subheading {
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: 550;
    text-align: start;
    margin-bottom: 5px;
  }

  .paragraph-style {
    margin: 0px 0; /* Adjust the margin as needed */
  }

 /* Adjust the height and other properties as needed */
.image-right {
  height: 300px;
  /* Additional styling as needed */
}

/* Adjust the size and other properties for the custom large image */
.image-large {
  height: 500px;
  /* Additional styling as needed */
}

  

  .service-reverse {
    flex-direction: row-reverse;
  }
  
  /* .service-section-reverse {
    flex-direction: row-reverse;
  }
   */
  
  .custom-heading {
    font-size: 24px;
    font-weight: bold;
  }
  
  /* Media queries for responsiveness */
  @media (min-width: 768px) {
    .custom-gap-container {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      width: 80%;
    }
  
    .custom-subheading {
      font-size: 24px;
    }
  }
  