/* Loading screen container styles */
.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('https://images.pexels.com/photos/7516347/pexels-photo-7516347.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2') center/cover no-repeat; /* Add your image path */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Ensure it's above other elements */
  }
  
  /* Apply frosted glass effect with darker background */
  .loading-screen::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Darker semi-transparent dark background */
    backdrop-filter: blur(5px); /* Apply a blur effect to the background */
    opacity: 0.9; /* Adjust the opacity to control transparency */
  }
  
  /* Hidden state (initially) */
  .loading-screen.hidden {
    opacity: 0;
    pointer-events: none; /* Make sure it doesn't interfere with clicks while hidden */
  }
  
  
  /* Spinning logo styles */
  .loading-logo {
    width: 110px; /* Adjust the size as needed */
    height: 140px; /* Adjust the size as needed */
    opacity: 1; /* Increase the opacity to make the logo more visible */
    animation: spin 2s linear infinite; /* Animation duration and type */
  }
  
  /* Keyframes for spinning animation */
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  