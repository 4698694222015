.carousel-container {
    position: relative;
    
  }

  .carousel-caption {
    display: flex;
    justify-content: center;
    gap: 140px;
    margin-bottom: 40px;
  }
/* Styles for larger screens (e.g., screens wider than 768px) */
.pc-only-image {
  height: 850px;
  object-fit: cover;
}

  
  
  .carousel-caption > div {
    width: 160px;
    height: 80px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    backdrop-filter: blur(3px);
    padding: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .carousel-caption p {
    font-size: 15px;
    margin: 5px 0;
    color: #fff;
    overflow: hidden; /* Prevent text overflow */
    white-space: nowrap; /* Prevent line wrapping */
    text-overflow: ellipsis; /* Add ellipsis for overflow text */
  }

  
  .mini-boxes {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 50px;
    width: 100%;
    z-index: 1;
  }
  
  .mini-box {
    width: 140px;
    height: 75px; /* Adjust the height as needed */
    margin: 0 75px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 10px; /* Adjust the border radius for the rounded shape */
    backdrop-filter: blur(3px);
    transition: background-color 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #FFFFFF;
    
    font-size: 14px;
  }

  .image-text {
    position: absolute;
    top: 20%;
    left: 10px; 
    transform: translateY(-50%);
    z-index: 1;
  }
  
  .mini-box.active {
    background-color: rgba(255, 255, 255, 0.6);
  }
  

  @media (max-width: 768px) {
    .carousel-caption {
      gap: 40px; /* Reduce the gap between items */
    }
  
    .carousel-caption > div {
      width: 120px; /* Reduce the width */
      height: 60px; /* Reduce the height */
    }
  
    .carousel-caption p {
      font-size: 10px; /* Adjust the font size */
    }
  }
  
  /* Media query for even smaller screens */
  @media (max-width: 576px) {
    .carousel-caption {
      gap: 20px; /* Further reduce the gap */
    }
  
    .carousel-caption > div {
      width: 100px; /* Further reduce the width */
      height: 50px; /* Further reduce the height */
    }
  
    .carousel-caption p {
      font-size: 18px; /* Further adjust the font size */
    }
  }
  
  /* Media query for mobile phones */
  @media (max-width: 480px) {
    .carousel-caption {
      flex-direction: column; /* Stack items vertically */
      gap: 10px; /* Reduce gap */
      align-items: center;
      text-align: center;
    }
  
    .carousel-caption > div {
      width: 100%; /* Full width */
      height: auto; /* Auto height */
      padding: 10px; /* Adjust padding */
    }
  
    .carousel-caption p {
      font-size: 16px; /* Smaller font size */
    }
  }