
/* #deadlineTime{
    border: none;
} */

/* Example CSS */
.custom-time-picker {
    /* Your custom styling for TimePicker */
    /* For example, to remove border: */
    border: none;
  }
  
  .custom-time-picker-input {
    /* Your custom styling for Input */
    /* For example, to remove border: */
    border: none;
  }

  .custom-modal{
    top:20%;
    left:20%
  }

 
  
  

  /* Dropdown Container Styles */
.dropdown-container {
  position: relative;
  margin: 20px;
}

/* Toggle Button Styles */
.toggle-button {
  background-color: #2F9AE6;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

/* Dropdown Content Styles */
.dropdown-content {
  position: absolute;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-in-out;
  width: 100%;
}

/* Dropdown Option Styles */
.dropdown-option {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  transition: background-color 0.2s;
}

.dropdown-option:hover {
  background-color: #f5f5f5;
}

/* Book Button Styles */
.book-button {
  background-color: #2F9AE6;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.book-button:hover {
  background-color: #007acc;
}
